import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import { ToastContainer } from 'react-toastify';


import * as mainActions from '../actions/main';
import Login from './Login';
import Main from './Main';
import Satisfaction from './Satisfaction';
import NavBar from '../components/NavBar';
import Redirect from 'react-router-dom/Redirect';



export const bHistory = createHistory();

class AppRouter extends Component {

    render() {
        const { logged } = this.props.main;

        return (
            <Router history={bHistory}>
                <>
                    <ToastContainer autoClose={5000} hideProgressBar={true} closeButton={false} />

                    {logged ?
                        <>
                            <NavBar />
                            <Switch>
                                <Route exact path="/" component={Main} />
                            </Switch>
                        </>
                        :
                        <>
                        <NavBar />
                        <Switch>
                            <Route exact path="/" component={ Main } />
                            <Route path="/login" component={ Login } />
                            <Route exact path="/satisfaction/:token" component={Satisfaction} />
                            <Route path="*" component={ () => <Redirect to="/"/> } />
                        </Switch>
                    </>
                    }
                </>
            </Router>
        )
    }
}


const mapStateToProps = (state) => ({
    main: state.main
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);