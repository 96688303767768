import React, { Component, Fragment } from "react";
import { Grid, Button, TextField, Card } from "@material-ui/core";
import * as mainActions from "../actions/main";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { bHistory } from "./Router";
import { toast } from "react-toastify";
import logo from '../resources/logo-reseller.jpeg'


class Login extends Component {
    constructor(props) {
        super(props);
        // Set default values to debug purposes
        this.state = {
            userName: "",
            passwd: ""
        };
    }

    componentDidMount() {
        const sessionData = sessionStorage.getItem("session");
        if (bHistory.location.pathname !== "/login" && !sessionData)
            bHistory.push("/login");
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = event => {
        if (event.keyCode === 13)
            this.login(this.state.userName, this.state.passwd);
    };

    changeValue = event => {
        let object = {};
        object[event.nativeEvent.target.name] = event.nativeEvent.target.value;
        this.setState(object);
    };

    login = async (userName, passwd) => {
        if (userName === "" || passwd === "")
            return toast("Introduce un usuario e un contrasinal.", {
                className: "toast-error",
                position: toast.POSITION.TOP_RIGHT
            });
        await this.props.actions.login(userName, passwd);
    };

    render() {
        const { userName, passwd } = this.state;
        const { translate } = this.props.main;
        return (
            <Fragment>
                <Grid container spacing={0} justify="center">
                    <header style={{ textAlign: "center", backgroundColor: "#fff", padding: "3em", color: "white", width: "100%" }}>
                        <img
                            width="330"
                            alt="csym"
                            src={logo}
                        />
                    </header>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Card style={{ width: '100%', padding: '2em', margin: '3em auto' }}>
                            <div
                                style={{
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                    fontWeight: "600",
                                    fontSize: "1.25em",
                                    color: '#005a99'
                                }}
                            >
                                {translate.login}
                            </div>
                            <TextField
                                id="userName"
                                name="userName"
                                label={translate.user}
                                margin="normal"
                                value={userName}
                                onChange={this.changeValue}
                                fullWidth={true}
                            />
                            <TextField
                                id="passwd"
                                name="passwd"
                                label={translate.password}
                                margin="normal"
                                value={passwd}
                                type="password"
                                onChange={this.changeValue}
                                fullWidth={true}
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ marginTop: '2em', backgroundColor: '#005a99', marginBottom: '2em' }}
                                onClick={() => {
                                    this.login(userName, passwd);
                                    console.log('login')
                                }}
                                fullWidth={true}>
                                <b>{translate.login}</b></Button>
                        </Card>
                    </div>
                </Grid>
            </Fragment>
        );
    }
}

//Connect state to use default test values
const mapStateToProps = state => ({
    main: state.main
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
