import { toast } from "react-toastify";
import API_URL from '../config/constants';
import { bHistory } from "../containers/Router";

export const login =  (userName, passwd) => {
    return async (dispatch) => {
        try {
            const request = new Request(`${API_URL}/login`, {
                method: 'POST',
                body: JSON.stringify({
                    username: userName,
                    password: passwd,
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            });
            const response = await fetch(request);
            
            const json = await response.json();
            if(json.code === 200){
                sessionStorage.setItem('logged', true)
                sessionStorage.setItem('sessionData', JSON.stringify(json.result));
                dispatch({ type: 'LOGIN', value: json.result });
                bHistory.push("/form");
            } else {
                return toast("Credenciales inválidas", {
                    className: "toast-error"
                });
            }
            
        } catch (err) {
            console.error(err);
            dispatch({ type: 'REQUEST_ERROR', value: { msg: 'Error interno en el servidor. Non se pueden obtener los datos.', code: 500 } })
        }
    }
}

export const logout = () => {
    return (dispatch) => {
        sessionStorage.clear();
        dispatch({ type: 'LOGOUT' });
    }
}