import React, { Component } from 'react';
import Router from './containers/Router';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import "react-toastify/dist/ReactToastify.css";
const store = configureStore();


class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router />
            </Provider>
        );
    }
}

export default App;
