import initialState from './initialState';

export default function mainReducer(state = initialState.main, action) {
    switch (action.type) {
        case "LOGIN": {
            return {
                ...state,
                sessionData: action.value,
                logged: true
            }
        }
        case "LOGOUT": {
            return {
                ...state,
                sessionData: null,
                logged: false
            }
        }
        default:
            return state;
    }
}
