export default {
    user: 'Usuario',
    password: 'Constraseña',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    sure_logout: '¿Seguro que quiere cerrar sesión?',
    yes: 'Si',
    no: 'No',
    client_name: 'Nombre del Cliente / Farmacia',
    contact_name: 'Persona de contacto',
    phone: 'Teléfono',
    email: 'Em@il',
    technical_service: 'Servicio técnico',
    doubs: 'Dudas Farmatic',
    laboratory: 'Laboratorio',
    type: 'Tipo',
    observations: 'Observaciones',
    send: 'Enviar'
}